import {Subscription} from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'rt-input-block-toggle',
  templateUrl: './input-block-toggle.component.html',
  styleUrls: ['./input-block-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputBlockToggleComponent),
      multi: true,
    },
  ],
})
export class InputBlockToggleComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('toggle') public toggle?: ElementRef<HTMLInputElement>;

  // Form control that stores the current state of the toggle.
  public readonly toggleControl = new FormControl<boolean>(false);

  // Component subscriptions. Will be unsubscribed on component destroy.
  public readonly subscription = new Subscription();

  /** Triggered whether the value is changed. Will be overwritten by Angular forms. */
  public onChange?: (value: boolean) => void;

  /** Triggered whether the control is touched. Will be overwritten by Angular forms. */
  public onTouched?: () => void;

  @HostBinding('class.disabled') protected isDisabled = false;

  constructor(protected renderer: Renderer2, protected cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    // Propagate the value changes to the form control.
    this.subscription.add(
      this.toggleControl.valueChanges.subscribe(value => {
        if (this.onChange) {
          this.onChange(value);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Required by ControlValueAccessor.
   * Function for overriding the `onChange` class method.
   */
  public registerOnChange(fn: (value: any) => void): void {
    // Override the class method with provided function.
    this.onChange = fn;
  }

  /**
   * Required by ControlValueAccessor.
   * Function for overriding the `onTouched` class method.
   */
  public registerOnTouched(fn: () => void): void {
    // Override the class method with provided function.
    this.onTouched = fn;
  }

  /**
   * Required by ControlValueAccessor.
   * Describes how to update the form model when the value changes.
   */
  public writeValue(value: boolean): void {
    this.toggleControl.setValue(value, {emitEvent: false});
  }

  /**
   * Required by ControlValueAccessor.
   * Describes what actions should be taken when the control status changes.
   */
  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (this.toggle) {
      this.renderer.setProperty(this.toggle.nativeElement, 'disabled', isDisabled);
    }
  }
}
