@if (editable && !preview && form.controls[name]) {
  <div
    [formGroup]="form"
    data-type="select"
    class="form-input-{{name}}"
    >
    <mat-form-field class="full-width" [floatLabel]="floatLabel">
      <mat-label>{{ label }}</mat-label>
      <mat-select [formControlName]="name" [required]="required" [tabindex]="tabindex"
        [disableOptionCentering]="disableOptionCentering" [panelClass]="disableOptionCentering ? 'v1-mat-select' : 'simple-select'">
        @for (option of optionList; track option) {
          <mat-option (click)="selectOption(option)" [value]="optionDict[option]">
            {{option}}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    @if (errors[name]) {
      <mat-error class="select-error-container">
        <span style="font-size: 75%">{{ errors[name] }}</span>
      </mat-error>
    }
  </div>
}
