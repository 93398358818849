<div
  [formGroup]="form"
  >
  <mat-checkbox
    [value]="value"
    [formControlName]="name"
    (blur)="onBlur()"
    (focus)="onFocus()"
    >
    <span class="label">{{label}}<ng-content></ng-content></span>
  </mat-checkbox>
  @if (errors[name]) {
    <mat-error>{{errors[name]}}</mat-error>
  }
</div>
