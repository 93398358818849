<div [formGroup]="form">
  <div>
    <label class="b-toggle">
      <input type="checkbox" [formControlName]="name">
      <span class="b-toggle__slider"></span>
    </label>
  </div>

  @if (errors[name]) {
    <mat-error>{{errors[name]}}</mat-error>
  }
</div>
