<div [formGroup]="form" class="autocomplete" data-type="text" data-spec="text-autocomplete">

  <mat-form-field
    class="full-width"
    floatLabel="auto"
    appearance="outline"
    >

    <mat-label>{{ label }}</mat-label>

    <input
      matInput
      #inputEl
      type="{{ type || 'text' }}"
      [formControlName]="name"
      [maxlength]="maxLength"
      [required]="required"
      [readonly]="readonly"
      [matAutocompleteDisabled]="autocompleteDisabled"
      [matAutocomplete]="auto"
      [placeholder]="placeholder"
      autocomplete="disable-autofill || false || off || none"
      >

    <mat-error>
      @if (errors[name]) {
        <span>{{errors[name]}}</span>
      }
    </mat-error>

    <mat-autocomplete #auto="matAutocomplete">
      @for (option of filteredOptions | async; track option) {
        @if (!!convertOption(option)) {
          <mat-option [value]="option">
            <span>{{convertOption(option)}}</span>
          </mat-option>
        }
      }
    </mat-autocomplete>

  </mat-form-field>
</div>

