import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import moment from 'moment';


@Component({
  selector: 'app-datepicker-wrapper',
  templateUrl: './datepicker-wrapper.component.html',
  styleUrls: ['./datepicker-wrapper.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerWrapperComponent),
      multi: true,
    },
  ],
})
export class DatepickerWrapperComponent implements ControlValueAccessor {

  @Input() label = '';
  @Input() error: string;
  @Input() required;
  @Input() formControlName: string;
  @Input() startAt = null;
  @Input() startView = 'month';
  @Input() min = null;
  @Input() max = null;
  @Input() note: string;
  @Input() floatLabel: string = 'always';

  protected _value = null;
  moment_value = null;
  disabled = false;
  dirty = false;
  constructor() {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  addEvent(event) {
    this.writeValue(event.value.format('YYYY-MM-DD'));
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.moment_value = moment(val, 'YYYY-MM-DD');
    this.onChange(val);
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  togle(picker) {
    picker.opened ? picker.close() : picker.open();
  }

}
