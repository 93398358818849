import { Component, OnInit, Input, ElementRef, ChangeDetectorRef, OnChanges } from '@angular/core';
import { InputBlockComponent } from '../input-block/input-block.component';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'rt-input-block-timepicker',
  templateUrl: './input-block-timepicker.component.html',
  styleUrls: ['./input-block-timepicker.component.scss']
})
export class InputBlockTimepickerComponent extends InputBlockComponent implements OnInit, OnChanges {

  @Input() label = '';
  @Input() name: string;
  @Input() form: UntypedFormGroup;
  @Input() model: any;
  @Input() fields: object;
  @Input() errors: object;
  @Input() required: boolean;

  @Input() format: number = 12; // Clock format (12 hours or 24 hours)
  @Input() min: string; // Min time value (string or DateTime)
  @Input() max: string; // Max time value (string or DateTime)

  protected _disabled = false;
  protected innerValue = '';

  constructor(public _eref: ElementRef, protected cd: ChangeDetectorRef) {
    super(_eref, cd);
  }

  @Input()
  set disabled(disabled) {
    this.setDisabled(disabled);
  }

  get disabled() {
    return this._disabled;
  }


  ngOnInit() {
    this.initValue();
    if (!this.label && this.fields && this.name) {
      this.label = this.innerValue;
    }
  }

  protected initValue() {
    if (this.editable) {
      const element = this.form.get(this.name);
      if (element) {
        this.setInnerValue(element.value);
        this.form.get(this.name).valueChanges.subscribe(value => this.setInnerValue(value));
      }
    }
  }

  setInnerValue(value) {
    this.innerValue = value;
  }

  ngOnChanges() {
    this.cd.detectChanges();
  }
}
