import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'rt-input-block-toggle-deprecated',
  templateUrl: './input-block-toggle-deprecated.component.html',
  styleUrls: ['./input-block-toggle-deprecated.component.scss']
})
export class InputBlockToggleDeprecatedComponent implements OnInit, OnChanges {
  @Input() label = '';
  @Input() name: string;
  @Input() form: UntypedFormGroup;
  @Input() fields: object;
  @Input() errors: object;
  @Input() required: boolean;
  @Input() value = 1;

  dirty = false;
  editable = true;

  protected _disabled = false;

  @Input()
  set disabled(disabled) {
    this.setDisabled(disabled);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  protected innerValue = '';

  constructor(public _eref: ElementRef, protected cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.initValue();
    if (!this.label && this.fields && this.name) {
      this.label = this.fields[this.name]['label'];
    }
    const ctrl = this.form.get(this.name);
    if (ctrl) {
      ctrl.valueChanges.subscribe(val => {
        this.dirty = ctrl.dirty;
      });
    }
  }

  ngOnChanges() {
    this.cd.detectChanges();
  }

  protected initValue() {
    if (this.editable) {
      const element = this.form.get(this.name);
      if (element) {
        this.setInnerValue(element.value);
        this.form.get(this.name).valueChanges.subscribe(value => this.setInnerValue(value));
      }
    }
  }

  protected setDisabled(disabled) {
    this._disabled = disabled;
    if (!this.form || !this.form.controls || !this.form.controls[this.name]) {
      return;
    }
    if (disabled) {
      this.form.controls[this.name].disable();
    } else {
      this.form.controls[this.name].enable();
    }
  }

  setInnerValue(value) {
    this.innerValue = value;
  }
}
