import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {InputBlockComponent} from '../input-block/input-block.component';

@Component({
  selector: 'rt-input-block-textarea',
  templateUrl: './input-block-textarea.component.html',
  styleUrls: ['./input-block-textarea.component.css'],
})
export class InputBlockTextareaComponent extends InputBlockComponent implements OnInit {

  @Input() label = '';
  @Input() name: string;
  @Input() editables: String[];
  @Input() preview = false;
  @Input() form: UntypedFormGroup;
  @Input() model: any;
  @Input() fields: object;
  @Input() errors: object;
  @Input() autosize = false;
  @Input() autosizeMinRows = 1;
  @Input() autosizeMaxRows = 5;
  @Input() tabindex = 0;
}
