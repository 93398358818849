<!-- <div *ngIf="editable && !preview" [formGroup]="person" class="rt-textarea" [ngClass]="{'invalid': errors[name]}">
<textarea [formControlName]="name" (blur)="onBlur()" (focus)="onFocus()"></textarea>
<label *ngIf="!placeholder" [ngClass]="{'top' : labelTop || focused}" (click)="focusInput()">
  <span>{{errors[name] || label}}</span>
  <span *ngIf="hasRequiredValidator()" class="rt-input-star">*</span>
</label>
</div> -->
<div [formGroup]="form">

  <mat-form-field
    class="full-width"
    [floatLabel]="floatLabel"
    data-type="textarea"
    >

    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    @if (autosize) {
      <textarea
        matInput
        matNativeControl
        cdkTextareaAutosize
        [cdkAutosizeMinRows]="autosizeMinRows"
        [cdkAutosizeMaxRows]="autosizeMaxRows"
        [value]="form.controls[name].value"
        [formControlName]="name"
        [placeholder]="placeholder"
        [tabindex]="tabindex"
      ></textarea>
    }
    @if (!autosize) {
      <textarea
        matInput
        matNativeControl
        [value]="form.controls[name].value"
        [formControlName]="name"
        [placeholder]="placeholder"
      ></textarea>
    }

    <mat-error>
      {{ errors[name] }}
    </mat-error>
  </mat-form-field>
</div>
