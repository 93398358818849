import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import {UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'rt-input-block-checkbox',
  templateUrl: './input-block-checkbox.component.html',
  styleUrls: ['./input-block-checkbox.component.css'],
})
export class InputBlockCheckboxComponent implements OnInit, OnChanges {

  @Input() label = '';
  @Input() name: string;
  @Input() editables: String[];
  @Input() preview = false;
  @Input() form: UntypedFormGroup;
  @Input() model: any;
  @Input() fields: object;
  @Input() errors: object;
  @Input() maxLength: number;
  @Input() required: boolean;
  @Input() value = 1;
  dirty = false;

  protected _disabled = false;

  @Input()
  set disabled(disabled) {
    this.setDisabled(disabled);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  editable = true;
  protected innerValue = '';
  private labelTop = false;
  private focused = false;

  @Input() preprocess: (val: String) => String = (val) => val;

  constructor(public _eref: ElementRef, protected cd: ChangeDetectorRef) { }

  ngOnInit() {
    // check if the model contains editable fields
    // if (this.editables) {
    //   this.editable = this.editables.indexOf(this.name) !== -1;
    // } else {
    //   this.editable = false;
    // }
    this.initValue();
    if (!this.label && this.fields && this.name) {
      this.label = this.fields[this.name]['label'];
    }
    const ctrl = this.form.get(this.name);
    if (ctrl) {
      ctrl.valueChanges.subscribe(val => {
        this.dirty = ctrl.dirty;
      });
    }
  }

  ngOnChanges() {
    this.cd.detectChanges();
  }

  protected initValue() {
    if (this.editable) {
      const element = this.form.get(this.name);
      if (element) {
        this.setInnerValue(element.value);
        this.form.get(this.name).valueChanges.subscribe(value => this.setInnerValue(value));
      }
    }
  }

  protected setDisabled(disabled) {
    this._disabled = disabled;
    if (!this.form || !this.form.controls || !this.form.controls[this.name]) {
      return;
    }
    if (disabled) {
      this.form.controls[this.name].disable();
    } else {
      this.form.controls[this.name].enable();
    }
  }

  setInnerValue(value) {
    this.innerValue = value;
    this.labelTop = !!value;
  }

  onBlur() {
    if (!this.innerValue) {
      this.labelTop = false;
    }
    this.focused = false;
  }

  onFocus() {
    this.labelTop = true;
    this.focused = true;
  }

  /**
   * @deprecated
   */
  focusInput() {
  }

  hasRequiredValidator() {
    if (this.fields && this.fields[this.name]) {
      return this.fields[this.name].validators.indexOf(Validators.required) !== -1;
    }
    return false;
  }
}
