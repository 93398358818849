<div [formGroup]="form" class="form-input-{{name}}">
  <mat-label [ngClass]="{'block': !inLineLabel}">
    <span class="label">{{label}}</span>
  </mat-label>
  <mat-radio-group [formControlName]="name">
    @for (option of optionList; track option) {
      <mat-radio-button
        color="primary"
        class="radio-button"
        (click)="selectOption(option)"
        [value]="optionDict[option]"
        [disabled]="disabled || readonly"
        >
        {{option}}
      </mat-radio-button>
    }
  </mat-radio-group>
</div>
@if (errors[name] && form.dirty) {
  <mat-error class="radio-error">{{ errors[name] }}</mat-error>
}
