import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {InputBlockComponent} from '../input-block/input-block.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'rt-input-block-radio',
  templateUrl: './input-block-radio.component.html',
  styleUrls: ['./input-block-radio.component.css']
})
export class InputBlockRadioComponent extends InputBlockComponent implements OnInit {

  @Input() label = '';
  @Input() name: string;
  @Input() editables: String[]; // ?
  @Input() preview = false;
  @Input() form: UntypedFormGroup;
  @Input() model: any; // ?
  @Input() fields: object; // ?
  @Input() errors: object = {};
  @Input() dsDisabled = [];

  @Input() inLineLabel = false;

  optionList = [];
  optionDirectDict = {};
  optionDict = {};
  selectedValue: string;

  @Input() selected: string;

  protected _choices;
  @Input()
  set choices(choices) {
    this._choices = choices;
    this.setOptions();
  }

  get choices() {
    return this._choices;
  }

  protected _options;
  @Input()
  set options(options) {
    this._options = options;
    this.setOptions();
  }

  get options() {
    return this._options;
  }

  protected setOptions() {
    this.optionDict = {};
    this.optionList = [];

    if (this._choices) {
      for (const opt of this._choices) {
        const key = opt[0];
        const val = opt[1];

        this.optionList.push(val);
        this.optionDict[val] = key;
        this.optionDirectDict[key] = val;
      }
    } else if (this._options) {
      for (const opt of this._options) {
        this.optionList.push(opt);
        this.optionDict[opt] = opt;
        this.optionDirectDict[opt] = opt;
      }
    }

    if (this.selected) {
      this.selectedValue = this.optionDirectDict[this.selected];
    }
  }

  dirty = false;

  get type() {
    return this.inLineLabel ? 'row' : 'column';
  }

  ngOnInit() {
    super.ngOnInit();
    const ctrl = this.form.get(this.name);
    if (ctrl) {
      ctrl.valueChanges.subscribe(val => this.dirty = ctrl.dirty || val);
      if (this.disabled && !ctrl.disabled) {
        this.form.controls[this.name].disable();
      }
    }
  }

  selectKey(key) { // ?
    const option = this.optionDirectDict[key];
    this.selected = key;
    this.selectedValue = option;
  }

  selectOption (option) {
    if (this.readonly) {
      return;
    }
    const key = this.optionDict[option];
    this.selected = key;
    this.selectedValue = option;
    this.form.controls[this.name].setValue(this.selected);
  }


  isClickable(val) {
    return this.dsDisabled.indexOf(val) === -1;
  }
}
