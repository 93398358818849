import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ValidationService} from './validation.service';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {InputBlockComponent} from './input-block/input-block.component';
import {InputBlockSelectComponent} from './input-block-select/input-block-select.component';
import {InputBlockTextareaComponent} from './input-block-textarea/input-block-textarea.component';
import {InputBlockCheckboxComponent} from './input-block-checkbox/input-block-checkbox.component';
import {DatepickerWrapperComponent} from './input-block-date/datepicker-wrapper/datepicker-wrapper.component';
import {InputBlockDateComponent} from './input-block-date/input-block-date.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {FileUploadModule} from 'ng2-file-upload';
import {InputBlockRadioComponent} from './input-block-radio/input-block-radio.component';
import {GooglePlaceAutocompleteComponent} from './google-place-autocomplete/google-place-autocomplete.component';
import {V2InputBlockSelectComponent} from './v2-input-block-select/v2-input-block-select.component';
import {InputBlockTimepickerComponent} from './input-block-timepicker/input-block-timepicker.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {InputBlockSignatureComponent} from './rt-input-block-signature/rt-input-block-signature.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {InputBlockToggleDeprecatedComponent} from './input-block-toggle-deprecated/input-block-toggle-deprecated.component';
import {BasicSelectAutocompleteInputComponent} from './basic-select-autocomplete-input/basic-select-autocomplete-input.component';
import {InputBlockAutocompleteComponent} from './input-block-autocomplete/input-block-autocomplete.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AngularSignaturePadModule} from '@almothafar/angular-signature-pad';
import {InputBlockToggleComponent} from './input-block-toggle/input-block-toggle.component';
import {RtTextInputComponent} from './rt-text-input/rt-text-input.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPageScrollModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatMomentDateModule,
    MatTooltipModule,
    FileUploadModule,
    NgxMaterialTimepickerModule,
    MatAutocompleteModule,
    AngularSignaturePadModule,
  ],
  exports: [
    InputBlockComponent,
    InputBlockAutocompleteComponent,
    InputBlockSelectComponent,
    InputBlockTextareaComponent,
    InputBlockCheckboxComponent,
    InputBlockDateComponent,
    InputBlockRadioComponent,
    GooglePlaceAutocompleteComponent,
    V2InputBlockSelectComponent,
    InputBlockTimepickerComponent,
    InputBlockSignatureComponent,
    InputBlockToggleComponent,
    InputBlockToggleDeprecatedComponent,
    BasicSelectAutocompleteInputComponent,
    RtTextInputComponent,
  ],
  declarations: [
    InputBlockComponent,
    InputBlockAutocompleteComponent,
    InputBlockSelectComponent,
    InputBlockTextareaComponent,
    InputBlockCheckboxComponent,
    InputBlockDateComponent,
    DatepickerWrapperComponent,
    InputBlockRadioComponent,
    GooglePlaceAutocompleteComponent,
    V2InputBlockSelectComponent,
    InputBlockTimepickerComponent,
    InputBlockSignatureComponent,
    InputBlockToggleComponent,
    InputBlockToggleDeprecatedComponent,
    BasicSelectAutocompleteInputComponent,
    RtTextInputComponent,
  ],
  providers: [ValidationService],
})
export class RtFormsModule {}
