<div [formGroup]="form">
  <mat-form-field class="full-width">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      [ngxTimepicker]="toggleTimepicker"
      [formControlName]="name"
      [format]="format"
      [min]="min"
      [max]="max"
      [required]="required"
      readonly
      >

    <div class="toggle-icon">
      <ngx-material-timepicker-toggle [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
    </div>
    <ngx-material-timepicker #toggleTimepicker></ngx-material-timepicker>
    <mat-error>
      @if (errors[name]) {
        <span>{{ errors[name]}}</span>
      }
    </mat-error>
  </mat-form-field>
</div>
