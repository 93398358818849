<div

  [formGroup]="form"
  [ngClass]="{'invalid': errors[name]}"
>
    <app-datepicker-wrapper
      [label]="label"
      [formControlName]="name"
      [error]="errors[name]"
      [required] = "required"
      [startAt]="startAt"
      [startView]="startView"
      [min]="min"
      [max]="max"
      [note]="note"
      [floatLabel]="floatLabel"
    ></app-datepicker-wrapper>
</div>

