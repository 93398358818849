<div [formGroup]="form">

  <mat-form-field [floatLabel]="floatLabel" class="full-width" [ngClass]="{'readonly': readonly, 'has-prefix': prefix}">

    <mat-label>{{ label }} <span class="label-note">{{note}}</span></mat-label>

    @if (prefix) {
      <span class="input-prefix">{{prefix}}</span>
    }

    <input
      matInput
      [tabindex]="tabindex"
      type="{{ type || 'text' }}"
      [formControlName]="name"
      [maxlength]="maxLength"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [required] = "required"
      placeholder="{{ placeholder || '' }}"
      [readonly]="readonly"
      >

    @if (showValidIcon && !errors[name] && form.get(this.name).value) {
      <div class="valid-icon">
        <img src="/assets/images/valid-input-icon.svg" alt="">
      </div>
    }

    @if (errors[name] && inlineError) {
      <div
        class="inline-error"
        [matTooltip]="errors[name]"
        #tooltip="matTooltip"
        (click)="tooltip.toggle()"
        matTooltipClass="inline-input-error-tooltip"
        >
        <img src="/assets/images/invalid-input-icon.svg" alt="">
      </div>
    }

    <mat-error>
      @if (!inlineError && errors[name]) {
        <span>
          {{ errors[name]}}
        </span>
      }
    </mat-error>

  </mat-form-field>
</div>
