import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {get} from 'lodash';


@Component({
  selector: 'rt-input-block-autocomplete',
  templateUrl: './input-block-autocomplete.component.html',
  styleUrls: ['./input-block-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputBlockAutocompleteComponent implements OnInit, AfterViewInit {
  @Input() label = '';
  @Input() name: string;
  @Input() editables: string[];
  @Input() preview = false;
  @Input() form: UntypedFormGroup;
  @Input() model: any;
  @Input() fields: object;
  @Input() errors: object;
  @Input() maxLength: number;
  @Input() required: boolean;
  @Input() readonly = false;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() autocompleteDisabled = true;
  @Input() optionPath;

  @Input() set autocompleteOptions(options: any[]) {
    this.setOptions(options);
  }

  /** Custom options will be added to usual options without filtering. */
  @Input() set customOptions(options: any[]) {
    const ctrl = this.form.get(this.name);
    this.filteredOptions = ctrl.valueChanges.pipe(
      startWith<string | any>(''),
      map(value => {
        if (typeof value === 'string') {
          return value;
        }
      }),
      map(name => (name ? this._filter(name) : this.options.slice())),
      map(filteredOptions => filteredOptions.concat(options)),
    );
  }

  @ViewChild('inputEl', {static: true}) inputEl: any;

  protected _disabled = false;
  formErrors: {NAME: string};

  @Input()
  set disabled(disabled) {
    this.setDisabled(disabled);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  editable = true;
  protected innerValue = '';
  public focused = false;

  options: any[] = [];
  filteredOptions: Observable<any[]>;

  @Input() preprocess: (val: any) => any = val => val;

  convertOption(val) {
    if (this.optionPath) {
      return get(val, this.optionPath, '');
    }
    return val;
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option[this.optionPath].toLowerCase().indexOf(filterValue) === 0);
  }
  constructor(protected cd: ChangeDetectorRef) {}

  ngOnInit() {
    const ctrl = this.form.get(this.name);
    if (!!this.form.controls) {
      this.filteredOptions = ctrl.valueChanges.pipe(
        startWith<string | any>(''),
        map(value => {
          if (typeof value === 'string') {
            return value;
          }
        }),
        map(name => (name ? this._filter(name) : this.options.slice()))
      );
      ctrl.valueChanges.subscribe(options => {
        if (typeof options === 'object' && !!options) {
          this.form.patchValue(options);
        }
      });
    }
    this.initValue();

    if (this.disabled && !ctrl.disabled) {
      ctrl.disable();
    }
  }

  ngAfterViewInit() {
    this.disableAutofill();
  }

  protected initValue() {
    if (this.editable) {
      const element = this.form.get(this.name);
      if (element) {
        this.setInnerValue(element.value);
        this.form.get(this.name).valueChanges.subscribe(value => this.setInnerValue(value));
      }
    }
  }

  protected setDisabled(disabled) {
    this._disabled = disabled;
    if (!this.form || !this.form.controls || !this.form.controls[this.name]) {
      return;
    }
    if (disabled) {
      this.form.controls[this.name].disable();
    } else {
      this.form.controls[this.name].enable();
    }
  }

  setInnerValue(value) {
    this.innerValue = value;
  }

  setOptions(option) {
    this.options = option;
  }

  hasRequiredValidator() {
    if (this.required === false || this.required === true) {
      return this.required;
    }
    if (this.fields && this.fields[this.name]) {
      return this.fields[this.name].validators.indexOf(Validators.required) !== -1;
    }
    return true;
  }

  onFocus() {}

  disableAutofill() {
    const observerHack = new MutationObserver(() => {
      observerHack.disconnect();
      if (this.inputEl.nativeElement) {
        this.inputEl.nativeElement.autocomplete = 'disable-autofill';
      }
    });
    observerHack.observe(this.inputEl.nativeElement, {
      attributes: true,
      attributeFilter: ['autocomplete', 'id'],
    });
  }
}
