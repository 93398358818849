import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit, ElementRef, OnDestroy,
 ChangeDetectorRef, Renderer2, SimpleChange, ChangeDetectionStrategy } from '@angular/core';
import { InputBlockComponent } from '../input-block/input-block.component';
import { UntypedFormGroup } from '@angular/forms';
import { isEqual } from 'lodash';
import {BehaviorSubject, Subject, Observable, Observer, combineLatest} from 'rxjs';
import {GooglePlaceService} from './google-place.service';
import {filter, first} from 'rxjs/operators';
// import { } from '@types/googlemaps';
const dataSetSource = new BehaviorSubject(false);

declare const google: any;
@Component({
// tslint:disable-next-line: component-selector
  selector: 'rt-google-place-autocomplete',
  templateUrl: './google-place-autocomplete.component.html',
  styleUrls: ['./google-place-autocomplete.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class GooglePlaceAutocompleteComponent extends InputBlockComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() adressType: string;
  @Input() label = '';
  @Input() name: string;
  @Input() editables: String[];
  @Input() preview = false;
  @Input() form: UntypedFormGroup;
  @Input() model: any;
  @Input() fields: object;
  @Input() errors: object;
  @Input() maxLength: number;
  @Input() required: boolean;
  @Input() prefix = '';
  @Input() sufix = '';
  @Input() mask: string;
  @Input() textMask: any;
  @Input() hint;
  @Input() viewFullAddress = false;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();

  globalEvent;
  simpleEvent;
  listener;
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    cd: ChangeDetectorRef,
    private places: GooglePlaceService,
  ) { super(el, cd); }

  @ViewChild('addresstext') addresstext: any;

  protected _readonly = false;
  protected dataSet$ = dataSetSource.asObservable();
  @Input()
  set readonly(readonly) {
    if (!isEqual(readonly, this._readonly)) {
      this._readonly = readonly;
      dataSetSource.next(readonly);
    }
  }
  get readonly() {
    return this._readonly;
  }
  protected _disabled = false;
  @Input()
  set disabled(disabled) {
    this.setDisabled(disabled);
  }

  get disabled(): boolean {
    return this._disabled;
  }


  ngOnInit() {
    this.places.load();
    this.simpleEvent = this.renderer.listen(this.addresstext.nativeElement, 'mousedown', (evt) => {
      if (this.globalEvent) {
        this.globalEvent();
      }
      if (!this.viewFullAddress) {
        this.modifyText(evt);
      }
    });
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  ngOnDestroy() {
    this.simpleEvent();
  }

  private getPlaceAutocomplete() {
    combineLatest([this.dataSet$, this.places.status$]).pipe(
      filter(([_, status]) => status.loaded),
      first(),
    ).subscribe(([dataSet, _]) => this.addListener(dataSet));
  }

  private addListener(dataSet) {
    let autocomplete;
    this.disableAutofill();
    if (typeof google === 'undefined') {
      return;
    }
    if (dataSet) {
      this._readonly = true;
      this.el.nativeElement.getElementsByTagName('mat-form-field')[0].style.pointerEvents = 'none';
    } else {
      this.el.nativeElement.getElementsByTagName('mat-form-field')[0].style.pointerEvents = 'initial';
      autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
        {
          componentRestrictions: { country: 'US' },
          types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
        });
      this.listener = google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        this.invokeEvent(place);
      });

    }
  }

  disableAutofill() {

    const observerHack = new MutationObserver(() => {
      observerHack.disconnect();
      if (this.addresstext.nativeElement) {
        this.addresstext.nativeElement.autocomplete = 'disable-autofill';
      }
    });
    observerHack.observe(this.addresstext.nativeElement, {
      attributes: true,
      attributeFilter: ['autocomplete']
    });
  }

  modifyText(event) {
    this.globalEvent = this.renderer.listen('window', 'mousedown', (events) => {
      if (events) {
        const pathList = Array.isArray(events['path']) ? events['path'] : [];

        pathList.forEach(path => {
          if (path.className === 'pac-item') {
            this.renderer.setStyle(this.addresstext.nativeElement, 'color', 'white');
            this.globalEvent();
            return;
          }
        });
      }
    });
  }

  invokeEvent(place: Record<string, any>) {
    if (!this.viewFullAddress) {
      const address = Array.isArray(place.address_components)
        ? place.address_components.filter(value => value?.types?.[0] === 'route') : [];

      if (address?.[0]?.long_name) {
        this.form.get(this.name).patchValue(address[0].long_name);
      }
      this.setAddress.emit({place, name: this.name});
    } else {
      this.form.get(this.name).patchValue(place?.formatted_address);
      return;
    }
    this.renderer.setStyle(this.addresstext.nativeElement, 'color', '#414654');
  }


}

