<div class="rt-text-input">
  <mat-form-field appearance="outline" class="full-width">
    @if (prefixElement) {
      <div class="rt-text-input__prefix" matPrefix>
        <ng-container *ngTemplateOutlet="prefixElement"></ng-container>
      </div>
    }

    <input
      #textInputElement
      matInput
      [type]="inputType"
      [formControl]="textInputControl"
      [placeholder]="placeholder || ''"
    >
  </mat-form-field>

  @if (errorMessage && ngControl.invalid && (ngControl.dirty || ngControl.touched)) {
    <mat-error class="rt-input-error">
      {{ errorMessage }}
    </mat-error>
  }
</div>
