<div [formGroup]="form" data-type="text" >
  <mat-form-field class="full-width" [floatLabel]="floatLabel">
    <mat-label>{{ label }}</mat-label>
    <input
      id="address"
      #addresstext
      matInput
      type="{{ type || 'text' }}"
      [formControlName]="name"
      [maxlength]="maxLength"
      [required]="required"
      [readonly]="readonly"
      [placeholder]="placeholder"
      >
    <!-- <span class="error-icon" *ngIf="errors[name]">
    <i class="fa fa-times"></i>
  </span> -->

  @if (errors[name]) {
    <mat-error>
      <!-- <img class="a-input-message-icon" src="/assets/img/error-icon.svg" alt="error-icon"> -->
      <span>{{ errors[name]}}</span>
    </mat-error>
  }

</mat-form-field >


</div>
