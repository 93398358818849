<mat-form-field class="example-full-width" [floatLabel]="floatLabel">
  <mat-label>{{ label }} <span class="label-note">{{note}}</span></mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    (dateInput)="addEvent($event)"
    [disabled]="disabled"
    [value]="moment_value"
    (click)="togle(picker)"
    [required]="required"
    [min]="min"
    [max]="max"
    readonly
    >

  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker [startAt]="startAt" [startView]="startView"></mat-datepicker>
</mat-form-field>
@if (error) {
  <mat-error class="datapicker-error-container">
    <span style="font-size: 12px">{{ error }}</span>
  </mat-error>
}
