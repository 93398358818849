<div class="setwidth signature-input" [formGroup]="form" data-type="text">
  <mat-form-field class="full-width" floatLabel="always">

    <mat-label [ngClass]="{'label--invalid': errors[name] && formControl.invalid && formControl.touched }">
      {{ label }}
    </mat-label>

    <input matInput [readonly]="readonly" required hidden="true">

    <div class="sign-container">
      <signature-pad
        #signature
        [options]="signaturePadOptions"
        (drawBeforeUpdate)="drawStart()"
        (drawAfterUpdate)="drawComplete()"
      >
      </signature-pad>
  </div>

  <div class="d-flex flex-row justify-content-end align-items-center">
    <button type="button" class="clear-button" (click)="clear()" [disabled]="!formValue || readonly">Clear Signature</button>
  </div>
</mat-form-field>

@if (errors[name] && formControl.invalid && formControl.touched) {
  <div class="error-message">
    {{ errors[name]}}
  </div>
}
</div>
