import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {InputBlockComponent} from '../input-block/input-block.component';
import {DocumentClickHandler} from '../../shared/classes/document-click-handler';

@Component({
  selector: 'rt-input-block-select',
  templateUrl: './input-block-select.component.html',
  styleUrls: ['./input-block-select.component.css'],
})
export class InputBlockSelectComponent extends InputBlockComponent implements OnInit, OnDestroy {

  @Input() label = '';
  @Input() name: string;
  @Input() editables: String[];
  @Input() preview = false;
  @Input() form: UntypedFormGroup;
  @Input() model: any;
  @Input() fields: object;
  @Input() errors: object;
  @Input() showPlaceholder = false;
  @Input() show: string;
  @Input() tabindex = 0;
  @Input() disableOptionCentering = false;

  @Input()
  set disabled(disabled) {
    this.setDisabled(disabled);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  optionList = [];
  optionDirectDict = {};
  optionDict = {};
  selectedValue: string;

  @Input() selected: string;

  protected _choices;
  @Input()
  set choices(choices) {
    this._choices = choices;
    this.setOptions();
  }

  get choices() {
    return this._choices;
  }

  protected _options;
  @Input()
  set options(options) {
    this._options = options;
    this.setOptions();
  }

  get options() {
    return this._options;
  }

  protected setOptions() {
    this.optionDict = {};
    this.optionList = [];

    if (this._choices) {
      for (const opt of this._choices) {
        const key = opt[0];
        const val = opt[1];

        this.optionList.push(val);
        this.optionDict[val] = key;
        this.optionDirectDict[key] = val;
      }
    } else if (this._options) {
      for (const opt of this._options) {
        this.optionList.push(opt);
        this.optionDict[opt] = opt;
        this.optionDirectDict[opt] = opt;
      }
    }

    if (this.selected) {
      this.selectedValue = this.optionDirectDict[this.selected];
    }
  }

  public scrollbarOptions: any;
  public isAlive = true;

  @HostBinding('class.opened') opened = false;
  constructor(public _eref: ElementRef, protected ref: ChangeDetectorRef) {
    super(_eref, ref);
    DocumentClickHandler.addHandler({
      component: this,
      handler: (e) => {
        if (!this._eref.nativeElement.contains(e.target) && this.opened) {
          this.opened = false;
          this.ref.detectChanges();
        }
      },
    });
  }

  protected initValue() {
    const element = this.form.get(this.name);
    if (element) {
      this.setInnerValue(this.optionDirectDict[element.value]);
      this.form.get(this.name).valueChanges.subscribe(value => {
        this.setInnerValue(this.optionDirectDict[value]);
        this.selectKey(value);
      });
    }
  }

  selectKey(key) {
    const option = this.optionDirectDict[key];
    this.selected = key;
    this.selectedValue = option;
  }

  selectOption(option) {
    const key = this.optionDict[option];
    this.selected = key;
    this.selectedValue = option;
    this.form.controls[this.name].setValue(key);
    this.form.controls[this.name].markAsDirty();

    // this.modelChange.emit(option[this.select]);
    this.opened = false;
    this.ref.detectChanges();
  }

  openDropDown () {
    if (this.form.controls[this.name].disabled) {
      return;
    }
    this.opened = true;
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  closeDropDown () {
    if (this.form.controls[this.name].enabled) {
      this.opened = false;
    }
  }

  keyDown (event) {
    if (this.form.controls[this.name].disabled) {
      return;
    }
    switch (event.key) {
      case 'Tab':
        this.closeDropDown();
        break;
      case 'Enter':
      case 'ArrowDown':
      case 'ArrowUp':
        this.openDropDown();
        break;
      case 'Escape':
        this.closeDropDown();
        break;
      default:
        event.preventDefault();
    }
  }
}
