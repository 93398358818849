import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Settings} from '../../../conf/settings.base';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {BehaviorSubject, Observable} from 'rxjs';
import {WINDOW} from '../../universal/window/window.service';

export interface Status {
  loaded: boolean;
  loading: boolean;
  error: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GooglePlaceService {

  public status: BehaviorSubject<Status> = new BehaviorSubject<Status>({
    error: false,
    loaded: false,
    loading: false
  });
  public status$ = this.status.asObservable();

  private initialized = false;
  constructor(
    private settings: Settings,
    @Inject(PLATFORM_ID) public platformId: any,
    @Inject(WINDOW) private window: Window,
  ) { }

  public getNativeDocument(): Document {
    if (isPlatformBrowser(this.platformId)) {
      return document;
    }
    return {} as Document;
  }

  public asStream(): Observable<Status> {
    this.load();
    return this.status.asObservable();
  }

  public isReady(): boolean {
    return this.status.getValue().loaded;
  }

  public load() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const status: Status = this.status.getValue();
    if (this.window.hasOwnProperty('google')) {
      this.status.next({
        error: false,
        loaded: true,
        loading: false
      });
    } else if (!status.loaded && !status.loading) {
      this.status.next({
        ...status,
        loading: true
      });

      const script = this.getNativeDocument().createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${this.settings.GOOGLE_PLACES_API_KEY}`;

      script.onload = () => {
        this.status.next({
          error: false,
          loaded: true,
          loading: false
        });
      };

      script.onerror = () => {
        this.status.next({
          error: true,
          loaded: false,
          loading: false
        });
      };

      this.getNativeDocument().body.appendChild(script);
    }
  }


  init() {
    if (this.initialized) {
      return false;
    }

    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }


    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${this.settings.GOOGLE_PLACES_API_KEY}`;
    document.body.appendChild(script);
    this.initialized = true;
  }
}
