import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {NgSignaturePadOptions, SignaturePadComponent} from '@almothafar/angular-signature-pad';

@Component({
  selector: 'rt-input-block-signature',
  templateUrl: './rt-input-block-signature.component.html',
  styleUrls: ['./rt-input-block-signature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputBlockSignatureComponent implements AfterViewInit {
  @Input() label = '';
  @Input() name: string;
  @Input() form: UntypedFormGroup;
  @Input() errors: object;
  @Input() readonly = false;
  @ViewChild('signature') signaturePad: SignaturePadComponent;
  public readonly signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 0.2,
    maxWidth: 2,
    canvasWidth: 300,
    canvasHeight: 250,
    penColor: '#1a4593',
  };
  protected innerValue = null;

  constructor(private cd: ChangeDetectorRef) {}

  get formValue() {
    return this.form.get(this.name).value;
  }

  get formControl() {
    return this.form.get(this.name);
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.forceCanvasResize();
    if (this.innerValue) {
      this.signaturePad.fromDataURL(this.innerValue);
    }
  }

  ngAfterViewInit() {
    if (this.readonly) {
      this.signaturePad.off();
    }

    this.setInnerValue(this.form.get(this.name).value);
    this.forceCanvasResize();
  }

  setInnerValue(value) {
    this.innerValue = value;
    if (value) {
      this.signaturePad.fromDataURL(value);
    }
  }

  drawComplete() {
    const data = this.signaturePad.toDataURL();
    this.form.get(this.name).setValue(data);
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
  }

  clear() {
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    this.form.get(this.name).setValue(null);
  }

  forceCanvasResize(): void {
    // NOTE!
    // This part was changed during the migration to Angular 17.
    // Incomplete as this part is not in use.
    // Before the migration, the signature component had a method `resizeCanvas` that was called here.
    // To make this work:
    // Add wrapper around signature pad and set width and height to 100%.
    // Get the wrapper element and set the width and height of the canvas to the width and height of the wrapper.
    console.error('Method not implemented.');
    // Call the redrawCanvas method of the signature pad.
    this.signaturePad.redrawCanvas();
  }

  protected initValue() {
    const element = this.form.get(this.name);
    if (element) {
      this.setInnerValue(element.value);
      this.signaturePad.fromDataURL(element.value);
    }
  }
}
