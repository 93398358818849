import {ChangeDetectorRef, Component, ElementRef, Input, OnInit} from '@angular/core';
import {InputBlockComponent} from '../input-block/input-block.component';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'rt-input-block-date',
  templateUrl: './input-block-date.component.html',
  styleUrls: ['./input-block-date.component.css'],
})

export class InputBlockDateComponent extends InputBlockComponent implements OnInit {

  @Input() label = '';
  @Input() name: string;
  @Input() editables: String[];
  @Input() preview = false;
  @Input() form: UntypedFormGroup;
  @Input() model: any;
  @Input() fields: object;
  @Input() errors: object;
  @Input() maxLength: number;
  @Input() required: boolean;
  @Input() startAt = null;
  @Input() startView = 'month';
  @Input() min = null;
  @Input() max = null;
  @Input() note: string;
  @Input() floatLabel: string = 'always';

  protected _disabled = false;

  @Input()
  set disabled(disabled) {
    this.setDisabled(disabled);
  }

  get disabled() {
    return this._disabled;
  }

  constructor(public _eref: ElementRef, protected cd: ChangeDetectorRef) {
    super(_eref, cd);
  }

}
